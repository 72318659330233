<template>
  <div class="main">
    <div class="cl">
      <div class="fl user-info">
        <el-upload class="avatar-uploader"
                   accept="image/*"
                   action="/v1/api/common/file"
                   :show-file-list="false"
                   :on-success="handleAvatarSuccess">
          <avatar/>
        </el-upload>
        <div class="nickname" v-if="nickname">{{nickname}}</div>
        <router-link class="button small button-primary btn-addName" :to="{name:'MyProfile'}" v-else>
          {{$t('AddName')}}
        </router-link>
      </div>
<!--      隐藏最新优惠按钮-->
<!--      <router-link class="fr btn-discount" :to="{name:'MessageList',query:{type:'news'}}">-->
<!--        {{$t('LatestOffers')}}-->
<!--      </router-link>-->

    </div>
    <div class="group order-info">
      <div class="hd">{{$t('OrderInformation')}}</div>
      <div class="bd cl">
        <router-link class="link-item" :to="{name:'OrderList'}">
          <img class="ico" src="../../assets/images/icon/all-order.png"/>
          <div class="label">{{$t('AllOrders')}}</div>
          <div class="value">{{allOrders}}{{$tc('Units',0)}}</div>
        </router-link>
        <router-link class="link-item" :to="{name:'OrderList',query:{active:'YES_GROUP'}}">
          <img class="ico" src="../../assets/images/icon/coming-soon.png"/>
          <div class="label">{{$t('ComingSoon')}}</div>
          <div class="value">{{futureOrders}}{{$tc('Units',0)}}</div>
        </router-link>
        <router-link class="link-item" :to="{name:'OrderList',query:{active:'FINISH'}}">
          <img class="ico" src="../../assets/images/icon/aircraft.png"/>
          <div class="label">{{$t('Departed')}}</div>
          <div class="value">{{outsetOrders}}{{$tc('Units',0)}}</div>
        </router-link>
        <router-link class="link-item" :to="{name:'OrderList',query:{active:'NOT_COMMENT'}}">
          <img class="ico" src="../../assets/images/icon/praise.png"/>
          <div class="label">{{$t('Comment')}}</div>
          <div class="value">{{comments}}{{$tc('Units',0)}}</div>
        </router-link>
      </div>
    </div>
    <div class="group" style="display:none">
      <div class="hd">
        <router-link class="btn-more" :to="{name:'MyCollection'}">
          <img src="../../assets/images/icon/more.png"/>
        </router-link>
        {{$t('LatestCollection')}}
      </div>
      <div class="cl">
        <theme-travel-item class="collection-item"
                           v-for="(item,index) of products"
                           :datas="item"
                           :index="index"
                           :list.sync="products"
                           :key="item.productId"/>
      </div>
    </div>
    <div class="group" v-if="isTravels==='Y'" style="display:none">
      <div class="hd">
        <router-link class="btn-more" :to="{name:'MyTravels'}">
          <img src="../../assets/images/icon/more.png"/>
        </router-link>
        {{$t('MyTravels')}}
      </div>
      <div class="my-travels cl">
        <router-link class="item" target="_blank"
                     v-for="item of travels"
                     :to="{name:'TravelsDetail',params:{id:item.travelsId}}"
                     :key="item.travelsId">
          <div class="cover ani-scale">
            <img class="img" :src="item.coverUrl" alt=""/>
          </div>
          <div class="title">
            <span>{{item.title}}</span>
            <span>&gt;</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import ThemeTravelItem from '../../components/ThemeTravelItem';
  import Avatar from '../../components/Avatar';
  import {getMyHomeData} from '../../api/mime';

  export default {
    name: 'My',
    data() {
      return {
        allOrders: 0,
        futureOrders: 0,
        outsetOrders: 0,
        comments: 0,
        products: [],
        travels: []
      };
    },
    computed: {...mapGetters(['nickname', 'isTravels'])},
    components: {Avatar, ThemeTravelItem},
    created() {
      this.getMyHomeData();
    },
    methods: {
      handleAvatarSuccess(res, file) {
        const {code, value} = res;
        if (code === 200) {
          this.$store.dispatch('user/updateAvatar', value.url);
        }
      },
      // 获取我的页面基础数据
      getMyHomeData() {
        getMyHomeData().then(res => {
          const value = res.value;
          this.allOrders = value.allOrders;
          this.futureOrders = value.futureOrders;
          this.outsetOrders = value.outsetOrders;
          this.comments = value.comments;
          this.products = value.products || [];
          this.travels = value.travels || [];
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .user-info{
    float:left;overflow:hidden;
    .avatar-uploader{float:left;font-size:0;}
    .nickname{float:left;margin-left:30px;line-height:90px;color:#000;font-size:20px;}
    .btn-addName{margin:29px 0 0 30px;}
    /deep/ .avatar{width:90px;height:90px;}
  }
  .group{
    margin-top:30px;
    &.order-info{margin-top:60px;}
    .hd{line-height:40px;overflow:hidden;color:#000;font-size:20px;border-bottom:1px dashed #feb7b0;}
    .btn-more{float:right;}
  }
  .order-info{
    .link-item{
      float:left;width:21%;margin:18px 2% 0;text-align:center;
      &:hover{opacity:0.8;}
    }
    .label{margin-top:12px;color:#000;font-size:18px;}
    .value{color:#ff6f61;font-size:16px;}
  }
  .collection-item{width:210px;margin-top:10px;margin-bottom:0;}
  .my-travels{
    .item{
      display:block;float:left;width:210px;margin-top:10px;margin-right:15px;cursor:pointer;border-radius:4px;box-shadow:0 2px 9px 0 rgba(255, 115, 101, 0.2);
      &:nth-child(3n){margin-right:0;}
      .cover{position:relative;height:135px;margin:5px 5px 0;}
      .title{
        line-height:50px;text-align:center;
        span{
          display:inline-block;vertical-align:middle;
          &:first-child{max-width:80%;padding-right:5px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
        }
      }
    }
  }
  .btn-discount{margin-top:23px;padding:0 23px;line-height:44px;color:#fff;font-size:18px;background-color:#ff6f61;border-radius:4px;}
</style>
