import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 获取我的页面基础数据
export function getMyHomeData() {
  return request({
    url: `/mime/index/${language}`,
    method: 'get'
  });
}
